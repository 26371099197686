import { OnAccountUpdateDocument, OnTransactionUpdateDocument } from './subscriptions.generated';

export type TpSubId = 'onAccountUpdate' | 'onTransactionUpdate';

export type TpSubscribeQuery = {
  id: TpSubId;
  query: string;
  variables?: Record<string, unknown>;
};

export const onAccountUpdateSubscription = {
  id: 'onAccountUpdate',
  query: OnAccountUpdateDocument,
} satisfies TpSubscribeQuery;

export const onTransactionUpdateSubscription = {
  id: 'onTransactionUpdate',
  query: OnTransactionUpdateDocument,
} satisfies TpSubscribeQuery;

export const subscriptions: TpSubscribeQuery[] = [
  onAccountUpdateSubscription,
  onTransactionUpdateSubscription,
];
