import type { AccountsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import type { QueryClient } from 'react-query';
import type { TpWsData } from '../hooks/SubscriptionProvider';

export function onAccountUpdate(client: QueryClient, payload: TpWsData['payload']): void {
  client.setQueryData<AccountsQuery | undefined>(['Accounts'], (old): AccountsQuery | undefined => {
    const accountUpdate = payload?.data?.onAccountUpdate;
    if (!accountUpdate) {
      return old;
    }

    const accounts = old?.accounts.items || [];

    /**
     * If the update has a higher VersionBalance, update the balance
     */
    const newAccounts = accounts.map((acc) => {
      if (acc.ID !== accountUpdate.Data.ID) {
        return acc;
      }
      if (acc.VersionBalance >= accountUpdate.Data.VersionBalance) {
        return acc;
      }
      return {
        ...acc,
        Balance: accountUpdate.Data.Balance,
        VersionBalance: accountUpdate.Data.VersionBalance,
      };
    });

    return {
      __typename: 'Query',
      accounts: {
        ...old?.accounts,
        items: newAccounts as AccountsQuery['accounts']['items'],
      },
    };
  });
}
