import type { TransactionsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import type { InfiniteData, QueryClient } from 'react-query';
import type { TpWsData } from '../hooks/SubscriptionProvider';

export function onTransactionUpdate(client: QueryClient, payload: TpWsData['payload']): void {
  client.setQueriesData<InfiniteData<TransactionsQuery> | undefined>(
    ['Transactions.infinite'],
    (old): InfiniteData<TransactionsQuery> | undefined => {
      const transactionUpdate = payload?.data?.onTransactionUpdate;
      if (!transactionUpdate) {
        return old;
      }

      const newPages = old?.pages.map((page) => ({
        ...page,
        transactions: {
          items: page.transactions.items.some(
            (transaction) => transaction.NoahID === transactionUpdate.Data.NoahID,
          )
            ? page.transactions.items.map((transaction) => {
                if (transaction.NoahID !== transactionUpdate.Data.NoahID) {
                  return transaction;
                }
                return { ...transaction, ...transactionUpdate.Data };
              })
            : [transactionUpdate.Data, ...page.transactions.items],
        },
      })) as TransactionsQuery[];

      return {
        pageParams: old?.pageParams || [],
        pages: newPages,
      };
    },
  );
}
